.login-box {
    background: #fff;
    border-radius: 15px;
    left: 50%;
    padding: 1em;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-alert {
    border-radius: 15px;
    left: 50%;
    padding: 1em;
    position: absolute;
    top: 70%;
    transform: translate(-50%, -50%);

    .spinner {
        color: #3897f0 !important;
        display: inline-block;
        margin-left: -3px;
        margin-top: 25px;
    }
}

.header-logo {
    font-size: 30px;
    padding: 5px 10px 20px;
    text-align: center;
}
