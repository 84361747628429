.items-list {
    padding-top: 40px;
}

.sidebar-hr {
    margin-left: 1rem;
    max-width: 150px;
    width: 85%;
}

.actions {
    text-align: center;

    svg {
        margin: 0 7px;
    }
}

.superserver-form {
    margin-bottom: 30px;
}

.hide-spinner {
    display: none;
}

.status-area {
    margin-bottom: 50px;
    margin-top: 30px;
}

.items-log {
    margin-bottom: 30px;
}

.logs {
    margin-top: 30px;
}

.alert {
    max-width: 75%;
    white-space: pre-wrap;
}

.modal-footer {
    justify-content: space-between;
}

#process-status {
    color: #fff;
}

#account-cleanup-form,
#contracts-form {
    margin-bottom: 20px;
}

.tasks-page {
    .nav-item {
        cursor: pointer;
    }

    .task-runner-button {
        margin: 10px 23px 20px 0;
    }

    .alert-msg {
        margin-top: 6px;
        max-width: 50%;
    }

    #tasks-content {
        margin-left: 1%;
        margin-top: 1%;
        width: 98%;
    }
}

.csr-tool-page {
    .nav-item {
        cursor: pointer;
    }

    .contract-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0;
        width: max-content;

        button {
            margin-right: 5px;
        }
    }

    .subscription-row {
        align-items: center;
        display: flex;
    }

    .contracts-table {
        margin-top: 2%;
    }

    .contracts-rows {
        margin-top: 2%;
        width: 50%;
    }

    .update-contract {
        margin-left: 5px;
    }
}

.socket-response {
    list-style: none;
    margin: 5% 0 0 5%;
    position: absolute;

    li {
        line-height: 2.7;
    }
}

#versions {
    padding-right: 20px;
}

.header-navbar {
    color: #fff;
    margin: 0 10px 0 0;
    white-space: nowrap;

    li {
        display: inline;
        margin-right: 20px;
    }

    a {
        color: #fff;
        font-weight: bold;
    }
}

.account-cleanup-page {
    #account-cleanup-form {
        text-align: left;
    }

    .account-cleanup-logs {
        background-color: #f5f9fd;
        border: 2px solid #9da0a3;
        height: 70vh;
        overflow: auto;
        padding-top: 25px;

        ul {
            list-style: none;
            position: relative;

            li {
                line-height: 2.7;
            }
        }
    }

    #logs-spinner {
        margin-left: 25px;
    }
}

.remove-plan-button {
    display: flex;
    flex-direction: row-reverse;
}

.plans-page {
    .add-config {
        margin-bottom: 30px;
    }

    .page-code {
        font-size: 1.2rem;
        padding-top: 1.4rem;
    }

    .action-button {
        text-align: center;
        vertical-align: middle;

        .action-icon {
            cursor: pointer;
        }
    }

    .col svg {
        cursor: pointer;
        margin-left: 0.3rem;
    }

    .preview-panel {
        .plans-preview {
            background-color: transparent;
            border: 2px solid #9da0a3;
            height: 70vh;
            overflow: auto;
        }

        #preview-spinner {
            margin-left: 25px;
            margin-top: 25px;
        }

        .preview-title {
            display: inline-block;
            font-size: 1.2rem;
            text-align: left;
        }

        .preview-hubspot-page {
            cursor: pointer;
            float: right;
        }
    }
}

.programs-page {
    .programs-form {
        margin-bottom: 20px;
        text-align: left;
    }

    .programs-button {
        margin-top: 1.8rem;
    }

    .season-buttons {
        background-color: none;
        border-color: none;
        color: rgb(0, 0, 0);
    }

    .results-table {
        padding: 1.5rem 0 0;
    }

    .pagination-form {
        padding-bottom: 10px;
    }

    .thumbnail {
        max-height: 200px;
    }
}

.title {
    margin-bottom: 2rem;
}

.modal-content {
    .title {
        margin-top: 2rem;
    }

    .spinner {
        margin: 1rem;
    }
}

.refund-input {
    width: 40%;
}
